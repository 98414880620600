import React, { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Link,
  Typography,
  Box,
  Menu,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { CartStateContext } from '../../store/StateProvider';
import { ShoppingCartBadge } from '../Badge/Badge';
import classNames from 'classnames';
import { headerStyle } from './HeaderStyle';
import { calculateTotalNumber } from '../../helpers/productsHelper';
import { CategoryNodeTree } from '../../interfaces/contentful';
import Paper from '@material-ui/core/Paper';
import { Button } from '../Button/Button';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import { FormattedMessage } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles(headerStyle);
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

// #hmm https://github.com/gatsbyjs/gatsby/issues/16682
// const BlogPostLink = React.forwardRef<any, Omit<GatsbyLinkProps<any>, 'to'>>(function namedPostLink(props, ref) {
//   return <GatsbyLink ref={ref} to="/posts" {...props} />;
// });

interface HeaderProps {
  changeColorOnScroll?: {
    height: number;
    color: 'primary' | 'white' | 'green';
  };
  color: 'transparent' | 'primary';
  absolute?: boolean;
  fixed?: boolean;
  categoryTree: CategoryNodeTree[];
}

// interface LogoAsset {
//   file: {
//     childImageSharp: {
//       fixed: FixedObject;
//     };
//   };
// }

// see https://www.freecodecamp.org/news/how-to-use-recursion-in-react/
export const CategoryMenuItem: React.FC<{ category: CategoryNodeTree; handleClose: any; level?: number }> = ({
  category,
  handleClose,
  level = 0,
}) => {
  const classes = useStyles();

  // see https://gist.github.com/FranciscoG/0c19eb81869fd2af0100eb7af8b527ab for more examples fromCharCode
  return (
    <>
      <li className={classes.listItem} key={category.contentful_id} onClick={handleClose}>
        <GatsbyLink to={`/categories/${category.slug}`}>
          <span
            style={{
              paddingLeft: `${level <= 1 ? level * 8 : level * 16}px`,
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            {level >= 1 && <SubdirectoryArrowRightIcon style={{ fontSize: '17px' }} />}
            {category.title}
          </span>

          {/*{String.fromCharCode(8212).repeat(level)}*/}
        </GatsbyLink>
      </li>
      {category.childNodes.map((catChild) => {
        return (
          <CategoryMenuItem
            key={catChild.contentful_id}
            category={catChild}
            handleClose={handleClose}
            level={level + 1}
          />
        );
      })}
    </>
  );
};

export const Header: React.FC<HeaderProps> = ({ changeColorOnScroll, color, fixed, absolute, categoryTree }) => {
  const { products } = useContext(CartStateContext);
  const numberOfProducts = calculateTotalNumber(products);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // @see https://www.gatsbyjs.org/docs/gatsby-image/ and https://www.gatsbyjs.org/docs/recipes/working-with-images/ for more info
  // const data = useStaticQuery<LogoAsset>(graphql`
  //   query {
  //     file(relativePath: { eq: "logo.png" }) {
  //       childImageSharp {
  //         fixed(width: 50, height: 50, quality: 90) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `);
  const classes = useStyles();
  const theme = useTheme();
  const [arrowRef, setArrowRef] = useState(null);

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (changeColorOnScroll) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      }
    }
  };

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return (): void => {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Box width={1} display="flex" justifyContent="space-between" alignItems="center">
          <nav aria-label="Main navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <Link className={classes.mainLink} component={GatsbyLink} to="/">
              <HomeIcon fontSize="small" />
            </Link>

            <Link style={{ margin: '0 0 0 4px' }} className={classes.mainLink} component={GatsbyLink} to="/about">
              <InfoIcon fontSize="small" />
              <strong style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="breadcrumbs.about" defaultMessage="About" />
              </strong>
            </Link>

            <Button
              style={{ paddingLeft: '17px', paddingRight: '15px', margin: '0 4px', color: 'black' }}
              color="success"
              ref={anchorRef}
              aria-controls={open ? 'catMenu' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <AccountTreeIcon />
              <strong>
                <FormattedMessage id="main.categories" defaultMessage="Categories" />
              </strong>
              <ExpandMoreIcon style={{ marginRight: '0px' }} />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-start"
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
                arrow: {
                  enabled: false,
                },
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper className={classes.paperContainer} elevation={5}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <ul className={classes.list} id="catMenu" style={{ listStyleType: 'none' }}>
                        {categoryTree.map((category) => (
                          <CategoryMenuItem
                            key={category.contentful_id}
                            category={category}
                            handleClose={handleClose}
                          />
                        ))}
                      </ul>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </nav>

          {/*<Menu*/}
          {/*  open={Boolean(anchorEl)}*/}
          {/*  id="catMenu"*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  keepMounted*/}
          {/*  onClose={handleMenuClose}*/}
          {/*  PaperProps={{ style: { maxHeight: 210, width: '50ch' } }}*/}
          {/*>*/}
          {/*  {categoryTree.map((category) => (*/}
          {/*    <MenuItem key={category.contentful_id} onClick={handleMenuClose}>*/}
          {/*      {category.title}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</Menu>*/}
          {/*<Box display={{ xs: 'none', md: 'block' }}>*/}
          {/*  <Link className={classes.title} component={GatsbyLink} to="/">*/}
          {/*    /!*<Img fixed={data.file.childImageSharp.fixed} alt="Logo" />*!/*/}
          {/*    <Typography variant="h6" style={{ color: theme.palette.info.main, paddingLeft: '10px' }}>*/}
          {/*      Liever uit de Streek*/}
          {/*    </Typography>*/}
          {/*  </Link>*/}
          {/*</Box>*/}
          <Link component={GatsbyLink} to="/cart">
            <ShoppingCartBadge quantity={numberOfProducts} />
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const DefaultHeader: React.FC<Pick<HeaderProps, 'categoryTree'>> = ({ categoryTree }) => (
  <Header
    categoryTree={categoryTree}
    fixed
    color="transparent"
    changeColorOnScroll={{
      height: 100,
      color: 'green',
    }}
  />
);
